import * as types from "./actionTypes";
import * as timesheetApi from "../../api/timesheetApi";
import { getPerson } from "../../api/personApi";
import {
  loadTimesheet,
  updateTimesheetHours,
  updateTimesheet
} from "./timesheetActions";
import { handleError } from "../../api/apiUtils";
import { formatDollars, formatNumber } from "../../utils/utils";
import { totalEarnings } from "../../utils/timesheetUtils";
import {
  makeDataCardFromPerson,
  getTimeCardsToAdd,
  getNextCardId
} from "../../utils/personUtils";
import { hasPerm, perm } from "../../utils/userUtils";

export function lockTimesheet(id, userId) {
  // thunk middleware needs this signature of function
  return async function (dispatch) {
    dispatch({ type: types.currentEdit.LOCK_TIMESHEET_BEGIN });
    // console.log("lock timesheet for user", id, userId);
    return timesheetApi
      .lockTimesheet(id, userId)
      .then(response => {
        dispatch({
          type: types.currentEdit.LOCK_TIMESHEET_SUCCESS,
          response
        });
      })
      .catch(error => {
        throw error;
      });
  };
}

export function unlockTimesheet(userId) {
  return async function (dispatch, getState) {
    dispatch({ type: types.currentEdit.UNLOCK_TIMESHEET_BEGIN });
    const timesheet = getState().currentEdit.timesheet;
    const user = getState().user;
    if (timesheet) {
      if (
        timesheet.lockedBy &&
        (timesheet.lockedBy === userId || hasPerm(user, perm.UT))
      ) {
        console.log("unlocking timesheet", userId);
        return timesheetApi.unlockTimesheet(timesheet, userId).then(response =>
          dispatch({
            type: types.currentEdit.UNLOCK_TIMESHEET_SUCCESS,
            response
          })
        );
      } else {
        console.log("Timesheet not locked by this user.");
        return Promise.resolve(timesheet);
      }
    } else {
      console.log("no current timesheet");
      return Promise.resolve(true);
    }
  };
}

// ?? Always use loadTimecardsWithHours instead?
export function loadTimecards(timesheetId) {
  return async function (dispatch) {
    dispatch({ type: types.currentEdit.LOAD_TIMECARDS_BEGIN });

    return timesheetApi
      .getTimecards(timesheetId)
      .then(timecards => {
        // console.log("got timecards; now dispatch success");
        dispatch({
          type: types.currentEdit.LOAD_TIMECARDS_SUCCESS,
          timecards
        });
      })
      .catch(error => {
        throw error;
      });
  };
}

const formatInitialTimecards = (cards = []) => {
  const flagged = flagMaintWithSick(cards);
  //console.log(flagged);
  return flagged.map(c => ({
    ...c,
    paidRate: formatDollars(c.paidRate, false),
    hours: (c.hours || []).map(h => ({ ...h, hours: formatNumber(h.hours) }))
  }));
};

// This function returns the time cards array with an added bool field, sickWithMaint
const flagMaintWithSick = (cards = []) => {
  const empty = { maintBits: 0, sickBits: 0 };
  // Use 14-bit numbers (1 bit per day) and bitwise comparison to see if MNTNC and Sick occur on the same day.
  // This empMaintSick function returns an object with a property for each employee Id.
  //   The value for each property is another object with maintBits and sickBits properties.
  //   e.g. {52: {maintBits: 1920, sickBits: 10112}, 98: {maintBits: 0, sickBits: 0}, 102: {maintBits: 7, sickBits: 3072}, }
  const empMaintSick = cards.reduce(
    (prev, c) => ({
      ...prev,
      [c.employeeId]: {
        maintBits:
          (prev[c.employeeId] || empty).maintBits |
          ((c.hideRate ? c.hours : []) || []).reduce(
            (prevHour, h) => (prevHour << 1) | (h.hours ? 1 : 0),
            0
          ),
        sickBits:
          (prev[c.employeeId] || empty).sickBits |
          ((c.hoursType === "Sick" ? c.hours : []) || []).reduce(
            (prevHour, h) => (prevHour << 1) | (h.hours ? 1 : 0),
            0
          ),
      },
    }),
    {}
  );
  // console.log(empMaintSick);
  return cards.map((c) => ({
    ...c,
    sickWithMaint:
      c.hoursType === "Sick" &&
      (empMaintSick[c.employeeId].maintBits &
        empMaintSick[c.employeeId].sickBits) >
        0,
  }));
};

export function loadTimecardsWithHours(timesheetId) {
  return async function (dispatch) {
    dispatch({ type: types.currentEdit.LOAD_TIMECARDS_AND_HOURS_BEGIN });

    return timesheetApi
      .getTimecardsWithHours(timesheetId)
      .then(timecards => {
        dispatch({
          type: types.currentEdit.LOAD_TIMECARDS_AND_HOURS_SUCCESS,
          timecards: formatInitialTimecards(timecards)
        });
      })
      .catch(handleError);
  };
}

const formatInitialEarnings = (earnings = []) => {
  return earnings.map(e => ({
    ...e,
    amount: formatDollars(e.amount, true, 4)
  }));
};

export function loadEarnings(timesheetId) {
  return async function (dispatch) {
    dispatch({ type: types.currentEdit.LOAD_EARNINGS_BEGIN });

    return timesheetApi
      .getTimesheetEarnings(timesheetId)
      .then(earnings => {
        // console.log(types.currentEdit.LOAD_EARNINGS_SUCCESS, earnings);
        dispatch({
          type: types.currentEdit.LOAD_EARNINGS_SUCCESS,
          earnings: formatInitialEarnings(earnings)
        });
      })
      .catch(error => {
        throw error;
      });
  };
}

export function loadComments(timesheetId) {
  return async function (dispatch) {
    dispatch({ type: types.currentEdit.LOAD_COMMENTS_BEGIN });

    return timesheetApi
      .getComments(timesheetId)
      .then(comments => {
        dispatch({
          type: types.currentEdit.LOAD_COMMENTS_SUCCESS,
          comments
        });
      })
      .catch(error => {
        throw error;
      });
  };
}

export function addComment(comment) {
  return async function (dispatch) {
    timesheetApi
      .addComment(comment)
      .then(() => dispatch(loadComments(comment.timesheetId)))
      .then(() => {
        dispatch({ type: types.currentEdit.ADD_COMMENT_SUCCESS, comment });
      });
  };
}

export function loadTimesheetDeep(tsid, personId) {
  return async function (dispatch) {
    dispatch({ type: types.timesheet.LOAD_TIMESHEET_START });
    return dispatch(lockTimesheet(tsid, personId))
      .then(() => dispatch(loadTimesheet(tsid)))
      .then(() => dispatch(loadTimecardsWithHours(tsid)))
      .then(() => dispatch(loadComments(tsid)))
      .then(() => dispatch(loadEarnings(tsid)));
  };
}

export function clearCurrentEdit(personId) {
  return async function (dispatch, getState) {
    const currentEdit = getState().currentEdit;
    if (
      currentEdit &&
      currentEdit.timesheet &&
      currentEdit.timesheet.lockedBy === personId
    ) {
      return dispatch(unlockTimesheet(personId)).then(() =>
        dispatch({ type: types.currentEdit.CLEAR_CURRENT })
      );
    } else {
      dispatch({ type: types.currentEdit.CLEAR_CURRENT });
    }
  };
}

export function updateCurrentTimesheet(sheet) {
  return function (dispatch) {
    return dispatch({ type: types.timesheet.LOAD_TIMESHEET_SUCCESS, sheet });
  };
}

export function updateCurrentTimecards() {
  return function (dispatch, getState) {
    const cards = getState().currentEdit.timecards;
    if (cards.length > 0) {
      // TODO: Combine API calls into a batch
      const promises = cards.map(async tc => {
        return await timesheetApi.processTimecard(tc);
      });
      return Promise.allSettled(promises).then(() => {
        dispatch({ type: types.currentEdit.SAVE_TIMECARDS_SUCCESS });
        return dispatch(loadTimecardsWithHours(cards[0].timesheetId));
      });
    } else {
      return Promise.resolve();
    }
  };
}

export function updateCurrentHours() {
  return function (dispatch, getState) {
    const cards = getState().currentEdit.timecards;
    if (cards.length > 0) {
      return timesheetApi
        .updateTimecardHoursBatch(cards)
        .then(() => dispatch(updateTimesheetHours()))
        .then(() => dispatch({ type: types.currentEdit.SAVE_HOURS_SUCCESS }));
    } else {
      return Promise.resolve(cards);
    }
  };
}

export function updateCurrentEarnings(sheet, cards) {
  return function (dispatch) {
    if (cards.length > 0) {
      // TODO: Combine API calls into a batch
      const promises = cards.map(async ec => {
        return await timesheetApi.processEarnings(ec);
      });
      return Promise.allSettled(promises).then(() => {
        dispatch({ type: types.currentEdit.SAVE_EARNINGS_SUCCESS });
        const earnings = totalEarnings(cards);
        return dispatch(updateTimesheet({ ...sheet, earnings })).then(() =>
          dispatch(loadEarnings(cards[0].timesheetId))
        );
      });
    } else {
      return Promise.resolve(cards);
    }
  };
}

export function saveAndClearCurrentEdit() {
  return (dispatch, getState) => {
    console.log("enter saveAndClearCurrentEdit");
    if (
      !getState().currentEdit ||
      !getState().currentEdit.timesheet ||
      !getState().currentEdit.hasChanges
    )
      return dispatch(clearCurrentEdit(getState().user.id));

    const saveChanges = () => {
      const cards = getState().currentEdit.timecards;
      const cardsChanged = (cards || []).reduce(
        (changed, c) => changed || c.metadata.isDirty || c.metadata.deleted,
        false
      );
      const hoursChanged = (cards || []).reduce(
        (changed, c) =>
          changed ||
          c.hours.reduce(
            (hChanged, h) => hChanged || (h.isDirty && !h.isNew),
            false
          ),
        false
      );
      if (cardsChanged) {
        console.log("cardsChanged");
        // TODO: Combine API calls into a batch
        const promises = cards.map(async tc => {
          return await timesheetApi.processTimecard(tc);
        });
        return Promise.allSettled(promises).then(() => {
          if (hoursChanged) {
            console.log("hoursChanged also");
            // return timesheetApi.updateTimecardHoursBatch(cards);
            return dispatch(updateCurrentHours());
          }
        });
      } else if (hoursChanged) {
        console.log("hoursChanged");
        // return timesheetApi.updateTimecardHoursBatch(cards);
        return dispatch(updateCurrentHours());
      } else {
        const sheet = getState().currentEdit.timesheet;
        const earningsCards = getState().currentEdit.earnings || [];
        const earningsChanged = earningsCards.reduce(
          (chg, e) => chg || e.metadata.isDirty || e.metadata.deleted,
          false
        );
        if (earningsChanged) {
          console.log("earningsChanged");
          // TODO: Combine API calls into a batch
          return dispatch(updateCurrentEarnings(sheet, earningsCards));
        } else {
          return Promise.resolve(false);
        }
      }
    };
    return saveChanges().then(() => {
      // console.log("Done saving if necessary, now clear current edit");
      return dispatch(clearCurrentEdit(getState().user.id));
    });
  };
}

export function addCurrentTimeCards(newCards) {
  return dispatch => {
    return dispatch({ type: types.currentEdit.ADD_TIMECARDS, newCards });
  };
}

export function addPeopleToCurrentTimeCards(peopleToAdd, rowTypes) {
  return (dispatch, getState) => {
    const currentEdit = getState().currentEdit;
    if (currentEdit && currentEdit.timesheet) {
      // Get each person with their rate for this pay period
      const timesheetId = currentEdit.timesheet.id;
      const payPeriodStart = currentEdit.timesheet.payPeriodStart;
      const payPeriodId = currentEdit.timesheet.payPeriodId;
      const timeCards = currentEdit.timecards ?? [];
      const companyId = currentEdit.timesheet.companyId;

      const promises = peopleToAdd.map(async empId => {
        return await getPerson(empId, payPeriodId);
      });
      return Promise.allSettled(promises).then(results => {
        const peopleCards = results
          .map(result => {
            if (result.status === "fulfilled") {
              return result.value;
            } else {
              console.log("Failed to get person: ", result.reason);
              return null;
            }
          })
          .filter(p => p !== null)
          .map(p => makeDataCardFromPerson(p, timesheetId, companyId));
        const newCards = getTimeCardsToAdd(
          rowTypes,
          peopleCards,
          getNextCardId(timeCards, "timecardId"),
          payPeriodStart
        );
        // console.log(newCards);
        console.log(`Adding ${newCards.length} timecards`);
        return dispatch(addCurrentTimeCards(newCards));
      });
    } else {
      return Promise.resolve(false);
    }
  };
}

export function removeCurrentTimeCard(cardId) {
  return dispatch => {
    return dispatch({ type: types.currentEdit.REMOVE_TIMECARD, cardId });
  };
}

export function updateCurrentTimeCardField(cardId, field, value) {
  return dispatch => {
    return dispatch({
      type: types.currentEdit.UPDATE_TIMECARD_FIELD,
      cardId,
      field,
      value
    });
  };
}

export function updateCurrentTimeCardHours(cardId, index, value) {
  return dispatch => {
    return dispatch({
      type: types.currentEdit.UPDATE_TIMECARD_HOURS,
      cardId,
      index,
      value
    });
  };
}

export function addCurrentEarningsCards(newCards) {
  return dispatch => {
    return dispatch({ type: types.currentEdit.ADD_EARNINGS, newCards });
  };
}

export function removeCurrentEarningsCard(cardId) {
  return dispatch => {
    return dispatch({ type: types.currentEdit.REMOVE_EARNINGS, cardId });
  };
}

export function updateCurrentEarningsCardField(
  cardId,
  field,
  value,
  isDirty = false
) {
  return dispatch => {
    return dispatch({
      type: types.currentEdit.UPDATE_EARNINGS_FIELD,
      cardId,
      field,
      value,
      isDirty
    });
  };
}

export function setHasChanges(hasChanges) {
  return async dispatch => {
    return dispatch({ type: types.currentEdit.UPDATE_HASCHANGES, hasChanges });
  };
}

export function updateNewComment(comment) {
  return dispatch => {
    return dispatch({ type: types.currentEdit.UPDATE_NEW_COMMENT, comment });
  };
}
