import { CognitoAuth } from "amazon-cognito-auth-js/dist/amazon-cognito-auth";
import {
  CognitoUserPool,
  CognitoRefreshToken
} from "amazon-cognito-identity-js";
import { config as AWSConfig } from "aws-sdk";
//import appConfig from '../config/app-config.json'
import moment from "moment";
import { config } from "../tools/config";

AWSConfig.region = config.env.REACT_APP_REGION;

const authKeyBase =
  "CognitoIdentityServiceProvider." + config.env.REACT_APP_AUTH_CLIENT_ID + ".";

export default class AuthService {
  createCognitoAuth = () => {
    const auth = new CognitoAuth({
      UserPoolId: config.env.REACT_APP_COGNITO_USER_POOL_ID,
      ClientId: config.env.REACT_APP_AUTH_CLIENT_ID,
      AppWebDomain: config.env.REACT_APP_AUTH_APP_DOMAIN,
      TokenScopesArray: config.env.REACT_APP_AUTH_TOKEN_SCOPES.split(","),
      RedirectUriSignIn: `${config.env.REACT_APP_AUTH_REDIRECT_URI}/callback`,
      RedirectUriSignOut: config.env.REACT_APP_AUTH_REDIRECT_URI
    });
    //console.log(auth);
    return auth;
  };

  login = () => {
    window.location.replace(this.getCognitoSignInUri());
  };

  logout = () => {
    localStorage.clear();
  };

  handleAuthentication = () => {
    console.log("Called callback..");

    if (typeof window !== "undefined") {
      return this.parseCognitoWebResponse(window.location.href) // parse the callback URL
        .then(() => this.getCognitoSession()) // get a new session
        .then(session => {
          //history.replace('/home');
          window.location.replace("/home");
          //history.push('/home');
        });
    }
  };

  parseCognitoWebResponse = href => {
    return new Promise((resolve, reject) => {
      const auth = this.createCognitoAuth();
      // userHandler will trigger the promise
      auth.userhandler = {
        onSuccess: function (result) {
          resolve(result);
        },
        onFailure: function (err) {
          reject(new Error("Failure parsing Cognito web response: " + err));
          console.warn(err);
        }
      };
      auth.parseCognitoWebResponse(href);
    });
  };

  getCognitoSession = () => {
    return new Promise((resolve, reject) => {
      const cognitoUser = this.createCognitoUser();
      cognitoUser.getSession((err, result) => {
        if (err || !result) {
          reject(new Error("Failure getting Cognito session: " + err));
          return;
        }
        // Resolve the promise with the session credentials
        //console.debug("Successfully got session: " + JSON.stringify(result));
        //const expires_at = result.accessToken.payload.exp;
        localStorage.setItem("expires_at", result.idToken.payload.exp);
        const session = {
          credentials: {
            accessToken: result.accessToken.jwtToken,
            idToken: result.idToken.jwtToken,
            refreshToken: result.refreshToken.token
          },
          user: {
            userName: result.idToken.payload["cognito:username"],
            email: result.idToken.payload.email
          }
        };
        resolve(session);
      });
    });
  };

  createCognitoUser = () => {
    const pool = this.createCognitoUserPool();
    return pool.getCurrentUser();
  };

  // Creates a CognitoUserPool instance
  createCognitoUserPool = () =>
    new CognitoUserPool({
      UserPoolId: config.env.REACT_APP_AUTH_USER_POOL_ID,
      ClientId: config.env.REACT_APP_AUTH_CLIENT_ID
    });

  // Get the number of seconds remaining before the idToken expires
  getTimeToExpiration = () => {
    const expiresAt = localStorage.getItem("expires_at") || 0;
    const currentDt = moment(new Date()).unix();
    // console.log(expiresAt - currentDt);
    return expiresAt - currentDt;
  };

  // checks if the user is authenticated
  isAuthenticated = () => {
    // Check whether the current time is past the access token's expiry time
    const lastAuthUser = authKeyBase + "LastAuthUser";
    return localStorage.getItem(lastAuthUser) && this.getTimeToExpiration() > 0;
  };

  // Get the URI of the hosted sign in screen
  getCognitoSignInUri = () => {
    const signinUri =
      `https://${config.env.REACT_APP_AUTH_APP_DOMAIN}${config.env.REACT_APP_AUTH_AUTHORIZE_PATH}` +
      `?identity_provider=${config.env.REACT_APP_AUTH_IDENTITY_PROVIDER}` +
      `&client_id=${config.env.REACT_APP_AUTH_CLIENT_ID}` +
      "&response_type=code" +
      `&scope=${config.env.REACT_APP_AUTH_TOKEN_SCOPES.split(",").join("+")}` +
      `&redirect_uri=${config.env.REACT_APP_AUTH_REDIRECT_URI}/callback`;
    console.log("signinuri :" + signinUri);
    return signinUri;
  };

  refreshToken = () => {
    const lastAuthUser = authKeyBase + "LastAuthUser";
    const currentUser = localStorage.getItem(lastAuthUser);
    if (currentUser != null) {
      const refreshToken = localStorage.getItem(
        authKeyBase + currentUser + ".refreshToken"
      );
      const token = new CognitoRefreshToken({ RefreshToken: refreshToken });
      const cognitoUser = this.createCognitoUser();
      cognitoUser.refreshSession(token, (err, session) => {
        if (err) throw err;

        localStorage.setItem("expires_at", session.idToken.payload.exp);
      });
    } else console.warn("ERROR: Could not get current user to refresh token.");
  };
}

export const setSession = session => ({
  type: "SET_SESSION",
  session
});
