// Common vars
const REACT_APP_VERSION = "1.1.193";
const REACT_APP_REGION = process.env.REACT_APP_REGION;
const REACT_APP_AUTH_TOKEN_SCOPES = process.env.REACT_APP_AUTH_TOKEN_SCOPES;
const REACT_APP_AUTH_AUTHORIZE_PATH = process.env.REACT_APP_AUTH_AUTHORIZE_PATH;

// Get local dev vars from .env file
const localDevEnv = {
  REACT_APP_VERSION,
  REACT_APP_REGION,
  REACT_APP_AUTH_TOKEN_SCOPES,
  REACT_APP_API_ROOT: process.env.REACT_APP_API_ROOT,
  REACT_APP_AUTH_USER_POOL_ID: process.env.REACT_APP_AUTH_USER_POOL_ID,
  REACT_APP_AUTH_CLIENT_ID: process.env.REACT_APP_AUTH_CLIENT_ID,
  REACT_APP_AUTH_APP_DOMAIN: process.env.REACT_APP_AUTH_APP_DOMAIN,
  REACT_APP_AUTH_AUTHORIZE_PATH,
  REACT_APP_AUTH_IDENTITY_PROVIDER:
    process.env.REACT_APP_AUTH_IDENTITY_PROVIDER,
  REACT_APP_AUTH_REDIRECT_URI: process.env.REACT_APP_AUTH_REDIRECT_URI,
  REACT_APP_CUSTOM_NODE_ENV: process.env.REACT_APP_CUSTOM_NODE_ENV
};

// Define each set of environment vars here
const devEnv = {
  REACT_APP_VERSION,
  REACT_APP_REGION,
  REACT_APP_AUTH_TOKEN_SCOPES,
  REACT_APP_API_ROOT: "https://devapi-gw.morantug.com",
  REACT_APP_AUTH_USER_POOL_ID: "us-east-2_1Gshm1WbV",
  REACT_APP_AUTH_CLIENT_ID: "2n2n928dm028ularn4nbdbvmsm",
  REACT_APP_AUTH_APP_DOMAIN: "moran.auth.us-east-2.amazoncognito.com",
  REACT_APP_AUTH_AUTHORIZE_PATH,
  REACT_APP_AUTH_IDENTITY_PROVIDER: "OneLogin",
  REACT_APP_AUTH_REDIRECT_URI: "https://devwebapp.morantug.com",
  REACT_APP_CUSTOM_NODE_ENV: "Development"
};
const stgEnv = {
  REACT_APP_VERSION,
  REACT_APP_REGION,
  REACT_APP_AUTH_TOKEN_SCOPES,
  REACT_APP_API_ROOT: "https://stgapi-gw.morantug.com",
  REACT_APP_AUTH_USER_POOL_ID: "us-east-2_DILJ4vwuJ",
  REACT_APP_AUTH_CLIENT_ID: "269smfose81ii1ihok78430659",
  REACT_APP_AUTH_APP_DOMAIN: "moranstg.auth.us-east-2.amazoncognito.com",
  REACT_APP_AUTH_AUTHORIZE_PATH,
  REACT_APP_AUTH_IDENTITY_PROVIDER: "OneLogin",
  REACT_APP_AUTH_REDIRECT_URI: "https://stgwebapp.morantug.com",
  REACT_APP_CUSTOM_NODE_ENV: "Staging"
};
const prodEnv = {
  REACT_APP_VERSION,
  REACT_APP_REGION,
  REACT_APP_AUTH_TOKEN_SCOPES,
  REACT_APP_API_ROOT: "https://api-gw.morantug.com",
  REACT_APP_AUTH_USER_POOL_ID: "us-east-2_3IssP8XBa",
  REACT_APP_AUTH_CLIENT_ID: "7ush57jdqbjgcjivv2h5emfsm3",
  REACT_APP_AUTH_APP_DOMAIN: "moran-prod.auth.us-east-2.amazoncognito.com",
  REACT_APP_AUTH_AUTHORIZE_PATH,
  REACT_APP_AUTH_IDENTITY_PROVIDER: "OneLogin",
  REACT_APP_AUTH_REDIRECT_URI: "https://webapp.morantug.com",
  REACT_APP_CUSTOM_NODE_ENV: ""
};

const host = () => {
  return window.location.hostname.toLowerCase();
};

export const config = {
  env:
    host() === "localhost"
      ? localDevEnv
      : host() === "devwebapp.morantug.com"
      ? devEnv
      : host() === "stgwebapp.morantug.com"
      ? stgEnv
      : prodEnv
};
