import React, { useState, useEffect, useMemo, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import Modal from "../common/Modal";
import PeoplePickerPage from "../people/PeoplePickerPage";
import Dropdown from "../common/Dropdown";
import { formatDollars, viewableCards } from "../../utils/utils";
import { hasPerm, perm } from "../../utils/userUtils";
import { hideRate } from "../../utils/timesheetUtils";
import { connect } from "react-redux";
import {
  loadHoursTypes,
  loadPositions,
} from "../../redux/actions/lookupActions";

const TimeSheetPositionPay = ({
  canEdit,
  hoursTypes,
  loadHoursTypes,
  positions,
  loadPositions,
  timeSheetData,
  onFieldChange,
  divisions,
  ...props
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const openModal = () => {
    setIsDialogOpen(true);
  };
  const closeModal = () => {
    setIsDialogOpen(false);
  };

  useEffect(() => {
    loadHoursTypes().catch((error) =>
      console.error("Failed to load Hours Types:", error)
    );
    loadPositions();
  }, [loadHoursTypes, loadPositions]);

  const handleFieldChange = useCallback(
    (cardId, fieldName, value) => {
      // console.log("update field", fieldName, value);
      if (canEdit && cardId && onFieldChange)
        onFieldChange("UPDATE_FIELD", cardId, fieldName, value);
    },
    [canEdit, onFieldChange]
  );

  const handleYesNoChange = useCallback(
    (cardId, fieldName, value) => {
      if (canEdit) {
        handleFieldChange(cardId, fieldName, value === "Yes");
        // If not overriding rate, reset paid rate to the default rate.
        if (value !== "Yes")
          handleFieldChange(
            cardId,
            "paidRate",
            timeSheetData.reduce(
              (rate, card) =>
                card.timecardId === cardId
                  ? formatDollars(card.defaultRate || 0, false)
                  : rate,
              formatDollars(0)
            )
          );
        else handleFieldChange(cardId, "paidRate", formatDollars(0));
      }
    },
    [canEdit, handleFieldChange, timeSheetData]
  );

  const userCanViewDivision = useCallback(
    (divisionId) => {
      // console.log("can user view division", divisionId, props.user.divisions);
      return (divisions || []).includes(divisionId);
    },
    [divisions]
  );

  // Correct the override and rate for rows with a division to which the user does not have access
  useEffect(() => {
    if (
      timeSheetData.find(
        (tc) => !tc.overrideRate && !userCanViewDivision(tc.divisionId)
      )
    ) {
      console.log("correct the override");
      timeSheetData.map((tc) => {
        if (!tc.overrideRate && !userCanViewDivision(tc.divisionId)) {
          handleYesNoChange(tc.timecardId, "overrideRate", "Yes");
        }
        return true;
      });
    }
  }, [timeSheetData, handleYesNoChange, userCanViewDivision]);

  const hoursTypesForCompany = useMemo(() => {
    // TODO: Deal with company IDs as an array and avoid string search non-uniqueness issues
    var compId = props.companyId.toString();
    var filteredTypes = hoursTypes
      .filter((h) => (h.companyIds || "").includes(compId))
      .sort((a, b) => {
        // Sort first by items only for this company
        return a.companyIds === compId && b.companyIds === compId
          ? a.code === b.code // sort by code for company-specific items (H30, H31, etc.)
            ? 0
            : a.code < b.code
            ? -1
            : 1
          : a.companyIds === compId
          ? -1
          : b.companyIds === compId
          ? 1
          : 0;
      });
    //console.log(filteredTypes);
    return filteredTypes;
  }, [hoursTypes, props.companyId]);

  const hoursTypesOptions = useMemo(() => {
    return hoursTypesForCompany.map((h) => h.description);
  }, [hoursTypesForCompany]);

  const handleHoursTypeChange = useCallback(
    (cardId, fieldName, value) => {
      if (fieldName === "hoursTypeId") {
        const selected = (hoursTypesForCompany || []).find(
          (t) => t.id.toString() === value.toString()
        );
        if (selected) {
          handleFieldChange(cardId, "hideRate", selected.hideRate);
          if (selected.hideRate) {
            handleYesNoChange(cardId, "overrideRate", "No");
            //handleFieldChange(cardId, "overrideRate", 0);
          }
        }
      }
      handleFieldChange(cardId, fieldName, value);
    },
    [hoursTypesForCompany, handleFieldChange, handleYesNoChange]
  );

  const handleAddPeople = (people, typesToAdd) => {
    if (props.onAddPeople) props.onAddPeople(people, typesToAdd);
  };

  const handleRemove = (cardId) => {
    if (canEdit && props.onRemoveCard) props.onRemoveCard(cardId);
  };

  const selectOnFocus = (evt) => {
    evt.target.select();
  };

  const handleRateChange = (cardId, value) => {
    const moneyRegEx = /^\d{0,4}(\.\d{0,4})?$/;
    if (moneyRegEx.test(value)) handleFieldChange(cardId, "paidRate", value);
  };

  const handleRateBlur = (evt) => {
    const { id, value } = evt.target;
    const cardId = parseInt(id);
    if (canEdit && cardId && props.onRateBlur) props.onRateBlur(cardId, value);
    else console.log("handleRateBlur is not properly configured");
  };

  const canShowRate = (tc) => {
    return (
      tc.defaultRate && !tc.isShoreside && userCanViewDivision(tc.divisionId)
    );
  };
  const displayRate = (tc) => {
    return hideRate(tc)
      ? ""
      : "$" +
          formatDollars(canShowRate(tc) && tc.defaultRate ? tc.defaultRate : 0);
  };

  return (
    <div className="timesheet-container">
      <table>
        <thead>
          <tr>
            <th className="col-one-half"></th>
            <th className="col-one-half" title="Emp ID">
              Emp ID
            </th>
            <th className="col-two" title="Crew Member">
              Crew Member
            </th>
            <th className="col-two" title="Hours Type">
              Hours Type
            </th>
            <th className="col-two" title="Home Division">
              Home Division
            </th>
            <th className="col-two" title="Paid Position">
              Paid Position
            </th>
            <th className="col-one" title="Default Rate">
              Default Rate
            </th>
            <th className="col-one" title="Override Rate">
              Override Rate
            </th>
            <th className="col-one" title="Paid Rate ($)">
              Paid Rate ($)
            </th>
          </tr>
        </thead>
        <tbody>
          {viewableCards(timeSheetData).map((tc, i) => (
            <tr
              key={tc.timecardId}
              className={i % 2 === 1 ? "normal-bkg" : "alternate-bkg"}
            >
              <td>
                {canEdit && (
                  <FontAwesomeIcon
                    icon={faMinusCircle}
                    title={`Remove: ${tc.employeeName} / ${tc.hoursType}`}
                    onClick={() => handleRemove(tc.timecardId)}
                    className={canEdit ? "clickable" : ""}
                  />
                )}
              </td>
              <td title={tc.moranId}>{tc.moranId}</td>
              <td
                title={
                  (tc.isShoreside ? "Shoreside" : "Mariner") +
                  ": " +
                  tc.employeeName
                }
              >
                {tc.employeeName}
              </td>
              <td title={tc.hoursType}>
                <Dropdown
                  types={hoursTypesForCompany}
                  id={tc.timecardId}
                  selectedValue={tc.hoursTypeId}
                  selectedText={tc.hoursType}
                  onChange={handleHoursTypeChange}
                  idColumn="hoursTypeId"
                  textColumn="hoursType"
                  canEdit={canEdit}
                />
              </td>
              <td title={tc.division}>{tc.division}</td>
              <td title={tc.position}>
                <Dropdown
                  types={positions}
                  id={tc.timecardId}
                  selectedValue={tc.positionId}
                  selectedText={tc.position}
                  onChange={handleFieldChange}
                  idColumn="positionId"
                  textColumn="position"
                  canEdit={canEdit}
                />
              </td>
              {hasPerm(props.user, perm.ORV) ? (
                <>
                  <td className="number" title={displayRate(tc)}>
                    {displayRate(tc)}
                  </td>
                  <td title={tc.overrideRate ? "Yes" : "No"}>
                    {canEdit ? (
                      <select
                        className="yes-no"
                        value={tc.overrideRate ? "Yes" : "No"}
                        onChange={(e) =>
                          handleYesNoChange(
                            tc.timecardId,
                            "overrideRate",
                            e.target.value
                          )
                        }
                        disabled={
                          !canEdit ||
                          (tc.overrideRate && !canShowRate(tc)) ||
                          hideRate(tc)
                        }
                      >
                        <option>No</option>
                        <option>Yes</option>
                      </select>
                    ) : tc.overrideRate ? (
                      "Yes"
                    ) : (
                      "No"
                    )}
                  </td>
                  <td
                    className="number"
                    title={
                      tc.overrideRate ? formatDollars(tc.paidRate, false) : ""
                    }
                  >
                    {tc.overrideRate && (
                      <>
                        {canEdit ? (
                          <input
                            type="text"
                            value={tc.paidRate}
                            id={tc.timecardId}
                            onFocus={selectOnFocus}
                            onChange={(e) =>
                              handleRateChange(tc.timecardId, e.target.value)
                            }
                            onBlur={handleRateBlur}
                            className="number dollars"
                            disabled={!canEdit}
                          />
                        ) : (
                          formatDollars(tc.paidRate, false)
                        )}
                      </>
                    )}
                  </td>
                </>
              ) : (
                <>
                  <td></td>
                  <td></td>
                  <td></td>
                </>
              )}
            </tr>
          ))}
          <tr>
            <td colSpan="9" className="timecard-no-border">
              {canEdit && <button onClick={openModal}>Add Rows</button>}
            </td>
          </tr>
        </tbody>
      </table>
      {canEdit && (
        <Modal isOpen={isDialogOpen} onClose={closeModal} title="People Picker">
          <PeoplePickerPage
            division={props.division}
            typesToAddLabel={"Hours Type"}
            typesToAddDefaults={[]}
            typesToAddOptions={hoursTypesOptions}
            onClose={closeModal}
            onAddPeople={handleAddPeople}
            cardCount={viewableCards(timeSheetData).length}
            maxCount={100}
          />
        </Modal>
      )}
    </div>
  );
};

// Redux
function mapStateToProps(state) {
  return {
    user: state.user,
    divisions: state.user.divisions,
    hoursTypes: state.hoursTypes,
    positions: state.positions,
  };
}
const mapDispatchToProps = { loadHoursTypes, loadPositions };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TimeSheetPositionPay);
